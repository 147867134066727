export { default as IconLogoWhite } from './svg/logoWhite.svg';
export { default as IconLogo } from './svg/logo.svg';
export { default as IconDoorLogin } from './svg/doorLogin.svg';
export { default as IconDoorLoginGrey } from './svg/doorLoginGrey.svg';
export { default as IconDoorLogout } from './svg/doorLogout.svg';
export { default as IconDoorLogoutGrey } from './svg/doorLogoutGrey.svg';
export { default as IconLangWorld } from './svg/langWorld.svg';
export { default as IconLangWorldBlue } from './svg/langWorldBlue.svg';
export { default as IconLongBlue } from './svg/logoBlue.svg';
export { default as IconTg } from './svg/tg.svg';
export { default as IconVk } from './svg/vk.svg';
export { default as IconYouTube } from './svg/youtube.svg';
export { default as IconTravelShow } from './svg/TravelShow.svg';
export { default as IconCamera } from './svg/camera.svg';
export { default as IconMicrophone } from './svg/microphone.svg';
export { default as IconPlayBtn } from './svg/playBtn.svg';
export { default as IconWarning } from './svg/warning.svg';
export { default as IconArrowSlider } from './svg/arrowSlider.svg';
export { default as IconWorld } from './svg/world.svg';
export { default as IconArrowDown } from './svg/arrowDown.svg';
export { default as IconRussian } from './countries/russia.svg';
export { default as IconThailand } from './countries/thailand.svg';
export { default as IconWorldTwo } from './svg/worldTwo.svg';
export { default as IconFilter } from './svg/filter.svg';
export { default as IconPin } from './svg/pin.svg';
export { default as IconNlo } from './svg/nlo.svg';
export { default as IconLogoSearchFirst } from './svg/logoSearchFirst.svg';
export { default as IconLogoSearchLast } from './svg/logoSearchLast.svg';
export { default as IconListCircle } from './svg/listCircle.svg';
export { default as IconEmptySearch } from './svg/emptySearch.svg';
export { default as IconWhiteStar } from './svg/whiteStar.svg';
export { default as IconWeb } from './svg/Web.svg';
export { default as IconMail } from './svg/mail.svg';
export { default as IconPhoneCall } from './svg/phoneCall.svg';
export { default as IconDownArrowMore } from './svg/downArrowMore.svg';
export { default as IconPublicPlace } from './svg/publicPlace.svg';
export { default as IconArrowRightSlide } from './svg/arrowRightSlide.svg';
export { default as IconCrockery } from './svg/crockery.svg';
export { default as IconUmbrella } from './svg/umbrella.svg';
export { default as IconKeyholeCircle } from './svg/keyholeCircle.svg';
export { default as IconCommentAltLines } from './svg/commentAltLines.svg';
export { default as IconFire } from './svg/fire.svg';
export { default as IconParkingCircle } from './svg/parkingCircle.svg';
export { default as IconBabyCarriage } from './svg/babyCarriage.svg';
export { default as IconPetArm } from './svg/petArm.svg';
export { default as IconStarActivity } from './svg/starActivity.svg';
export { default as IconChats } from './svg/chats.svg';
export { default as IconInfoCircle } from './svg/InfoCircle.svg';
export { default as IconInfoEnter } from './svg/enter.svg';
export { default as IconShield } from './svg/shield.svg';
export { default as IconLoaderAlt } from './svg/loaderAlt.svg';
export { default as IconCheckRead } from './svg/check_read.svg';
export { default as IconCheckUnRead } from './svg/check_unread.svg';
export { default as IconCategory } from './svg/category.svg';

export { default as IconMaster } from './PaymentMethods/master.svg';
export { default as IconMir } from './PaymentMethods/mir.svg';
export { default as IconSbp } from './PaymentMethods/sbp.svg';
export { default as IconTinkoffPay } from './PaymentMethods/tinkoffPay.svg';
export { default as IconVisa } from './PaymentMethods/visa.svg';
export { default as IconYandexPay } from './PaymentMethods/yandexPay.svg';
export { default as IconMeal } from './svg/meal.svg';
export { default as IconUsersAlt } from './svg/usersAlt.svg';
export { default as IconCross } from './svg/cross.svg';

export { default as IconSmartphoneSync } from './svg/smartphoneSync.svg';
export { default as IconRocketStartup } from './svg/rocketStartup.svg';
export { default as IconPostsSocial } from './svg/postsSocial.svg';
export { default as IconBusiness } from './svg/business.svg';
export { default as IconParachutist } from './svg/parachutist.svg';
export { default as IconCheckCircle } from './svg/check-circle.svg';
export { default as IconMap } from './svg/map.svg';
export { default as IconTelegramCircle } from './svg/telegram-circle.svg';
export { default as IconVkCircle } from './svg/vk-circle.svg';
export { default as IconUserCircle } from './svg/user-circle.svg';
export { default as IconSuitcase } from './svg/suitcase.svg';
export { default as IconMegaphone } from './svg/megaphone.svg';
export { default as IconTicket } from './svg/ticket.svg';
export { default as IconTicketGrey } from './svg/ticket-grey.svg';
export { default as IconUser } from './svg/user.svg';
export { default as IconUserGrey } from './svg/user-grey.svg';
export { default as IconQvedo } from './svg/qvedo.svg';

export { default as IconBgAccommodation } from './svg/filtersBackground/icon_bg_accommodation.svg';
export { default as IconBgActivity } from './svg/filtersBackground/icon_bg_activity.svg';
export { default as IconBgAttraction } from './svg/filtersBackground/icon_bg_attraction.svg';
export { default as IconBgPublicPlace } from './svg/filtersBackground/icon_bg_publicPlace.svg';
export { default as IconBgRestaurant } from './svg/filtersBackground/icon_bg_restaurant.svg';

export { default as IconMenu } from './svg/menu.svg';
export { default as IconMenuClose } from './svg/menuClose.svg';
export { default as IconProfile } from './svg/profile.svg';
export { default as IconProfileHeader } from './svg/profile-header.svg';
export { default as IconLanguage } from './svg/language.svg';
export { default as IconLogout } from './svg/logout.svg';
export { default as IconChat } from './svg/chat.svg';
export { default as IconArrowBlueDown } from './svg/ArrowBlueDown.svg';
export { default as IconList } from './svg/list.svg';
export { default as IconLogoText } from './svg/logoText.svg';
export { default as IconLoup } from './svg/loup.svg';
export { default as IconSettings } from './svg/settings.svg';
export { default as IconLoopWhite } from './svg/loop-white.svg';

export { default as IconMouse } from './svg/mouse.svg';
export { default as IconLogoFullBlue } from './svg/logoFullBlue.svg';
export { default as IconGlobe } from './svg/globe.svg';

export { default as IconMarkerActivity } from './svg/markers/activity.svg';
export { default as IconMarkerRestaurant } from './svg/markers/restaraunt.svg';
export { default as IconMarkerAttraction } from './svg/markers/attraction.svg';
export { default as IconMarkerInstitutions } from './svg/markers/Institutions.svg';
export { default as IconMarkerHotel } from './svg/markers/houtel.svg';

export { default as IconWallet } from './svg/wallet.svg';
export { default as IconPoint } from './svg/point.svg';

export { default as IconSort } from './svg/sort.svg';
export { default as IconAmenity } from './svg/amenity.svg';
export { default as IconBed } from './svg/bed.svg';
export { default as IconCrockeryGrey } from './svg/crockeryGrey.svg';

export * from './tsx/Star';

import React, { SVGProps } from 'react';

export const IconStar = ({ color = 'inherit', width = '1em', height = '1em', ...props }: SVGProps<SVGSVGElement>) => {
	return (
		<svg viewBox='0 0 12 11' width={width} height={height} fill='none' {...props} xmlns='http://www.w3.org/2000/svg'>
			<path
				fill={color}
				d='M6.24418 9.26671C6.09244 9.18177 5.90747 9.18177 5.75574 9.26671L3.41931 10.5745C3.04701 10.7829 2.60153 10.4593 2.68467 10.0408L3.20642 7.41452C3.2403 7.24396 3.18314 7.06804 3.05546 6.94998L1.08936 5.13196C0.776093 4.84229 0.946233 4.31858 1.36993 4.26833L4.02905 3.95296C4.20172 3.93248 4.35135 3.82377 4.42418 3.66588L5.54594 1.2342C5.72465 0.84679 6.27526 0.846791 6.45398 1.2342L7.57573 3.66588C7.64857 3.82377 7.7982 3.93248 7.97086 3.95296L10.63 4.26833C11.0537 4.31858 11.2238 4.84229 10.9106 5.13196L8.94445 6.94998C8.81678 7.06804 8.75961 7.24396 8.7935 7.41452L9.31524 10.0408C9.39838 10.4593 8.9529 10.7829 8.58061 10.5745L6.24418 9.26671Z'
			/>
		</svg>
	);
};

IconStar.displayName = 'IconStar';
